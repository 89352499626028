<template>
  <div class="home">
    <div class="nav">
      
    </div>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },

}
</script>
