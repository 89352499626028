import axios from "axios";
// import { utils } from "./utis";
import { ElMessage } from 'element-plus'
import HTTP_API_URL from "@/config/env.js"
import router from '../router/index';

const Authorization = 'Z2dlcjZ1eDNycTdxanEycXZjcGpiYXFyOnZiYWRzcGQ4cHRlbXlpcXZrd2djYXJoYzg5ZWtzaTR1'

export const uploadApi = axios.create({
  // baseURL: "http://www.youyouzhenxuan.com" + "/official",
  // baseURL: "http://121.41.66.8/api",
  baseURL: HTTP_API_URL,
  timeout: 30000,
  headers: {
    "Authorization": Authorization,
  },
  //跨域请求发送cookie
  withCredentials: true,
});

const instance = axios.create({
  // baseURL: "http://www.youyouzhenxuan.com" + "/official",
  // baseURL: "http://121.41.66.8/api",
  baseURL: HTTP_API_URL,
  timeout: 30000,
  //transformRequest在向服务器发送前，修改请求数据
  transformRequest: [
    function (data) {
      return JSON.stringify(data);
    },
  ],
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Authorization": Authorization,
  },
  //跨域请求发送cookie
  withCredentials: true,
});

instance.interceptors.request.use(
  function (config) {
    // console.log(config, "con");
    const msg = JSON.parse(sessionStorage.getItem('uavmsg'))
    if (msg) {
      const tenantId = msg.tenantId
      const token = msg.token
      config.headers.Token = token
      config.headers.tenantId = tenantId
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
)

uploadApi.interceptors.request.use(
  function (config) {
    const msg = JSON.parse(sessionStorage.getItem('uavmsg'))
    if (msg) {
      const tenantId = msg.tenantId
      const token = msg.token
      config.headers.Token = token
      config.headers.tenantId = tenantId
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
)

instance.interceptors.response.use(
  // 未完善
  function (response) {
    if (response.data?.code == 0) {
      return response.data;
    } else {
      return ElMessage.error(response.data.msg)
    }
  },
  function (error) {
    console.log(error, "11111");
    if (error && error.response) {
      // console.log()
      switch (error.response.status) {
        case 400:
          ElMessage.error(error.response.data.msg)
          break;
        case 401:
          // 登录超时
          // 退出登录 清空信息
          sessionStorage.removeItem('uavmsg')
          sessionStorage.removeItem('workspace_id')
          sessionStorage.removeItem('employeeId')
          localStorage.removeItem('username')
          router.push('/login')
          break;
        // case 403:
        //   error.message = "拒绝访问";
        //   break;
        // case 405:
        //   error.message = "请求方法未允许";
        //   break;
        // case 500:
        //   error.message = "服务器内部出错";
        //   break;
      }
    } else {
      return ElMessage.error(error?.response?.data?.msg) || ElMessage.error('接口访问失败')
    }
    // return Promise.reject(error);
  }
)

uploadApi.interceptors.response.use(
  function (response) {
    if (response.data?.code == 0) {
      return response.data;
    } else {
      return ElMessage.error(response.data.msg)
    }
  },
  function (error) {
    console.log(error, "11111");
    if (error && error.response) {
      // console.log()
      switch (error.response.status) {
        case 400:
          ElMessage.error(error.response.data.msg)
          break;
        // case 403:
        //   error.message = "拒绝访问";
        //   break;
        // case 405:
        //   error.message = "请求方法未允许";
        //   break;
        // case 500:
        //   error.message = "服务器内部出错";
        //   break;
      }
    } else {
      return ElMessage.error(error?.response?.data?.msg) || ElMessage.error('接口访问失败')
    }
    // return Promise.reject(error);
  }
)

export const liveRequest = axios.create({
  baseURL: HTTP_API_URL,
  timeout: 30000,
  transformRequest: [
    function (data) {
      return JSON.stringify(data);
    },
  ],
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Authorization": Authorization,
  },
  withCredentials: true,
});
liveRequest.interceptors.request.use(
  function (config) {
    const msg = JSON.parse(sessionStorage.getItem('uavmsg'))
    if (msg) {
      const tenantId = msg.tenantId
      const token = msg.token
      config.headers.Token = token
      config.headers.tenantId = tenantId
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
liveRequest.interceptors.response.use(
  function (response) {
    if (response.data?.code == 0) {
      return response.data;
    } else {
      console.log('[ response.data.msg ] >', response.data.msg)
    }
  },
  function (error) {
    console.log(error);
  }
);

export default instance;
