<template>
  
  <router-view/>
</template>

<script>

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 0);
    super(callback);
  }
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
 
}
html, body,#app{
  height:100%;
  width:100%;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
 
::-webkit-scrollbar-track {
	width: 5px;
	background-color:#232323;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
/*滚动条的设置*/
::-webkit-scrollbar-thumb {
	background-color:#3c3c3c;
	background-clip: padding-box;
	min-height: 28px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
/*滚动条移上去显示的的颜色*/
::-webkit-scrollbar-thumb:hover {
	/* background-color: red; */
}
img{
	-webkit-user-drag: none;
}
</style>
